@import url(https://fonts.googleapis.com/css?family=Inter:regular,bold,italic);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html,
body,
#root {
  height: 100%;
}

.download-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: white;
  cursor: pointer;
}

.mobile-hide {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}

html {
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.player {
  position: relative;
  font-size: 0;
  border-radius: 12px;
  overflow: hidden;

  object-fit: cover;
  overflow: hidden;
  height: 100%;
}

/* This css is only applied when fullscreen is active. */
.player:-webkit-full-screen {
  max-width: none;
  width: 100%;
}
.player:-ms-fullscreen {
  max-width: none;
  width: 100%;
}
.player:fullscreen {
  max-width: none;
  width: 100%;
}

.player:-webkit-full-screen {
  max-width: none;
  width: 100%;
}

.player__video {
  height: 87vh;
}

.player__time_left {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.9px;
  width: auto;
  text-align: start;
}

.player__time_right {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.9px;
  width: auto;
  text-align: end;
}

.player__slider {
  width: 10px;
  height: 30px;
}

.player__controls {
  display: flex;
  position: absolute;
  padding:  8px 12px;
  bottom: 0;
  transform: translateY(100%) translateY(-5px);
  transition: all 0.3s;
  flex-wrap: wrap;
  width: 100%;
}

.player:hover .player__controls {
  transform: translateY(0);
}

.player:hover .progress {
  height: 3px;
}
.player:hover .player__top__controls {
  opacity: 100;
}

.player__top__controls {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s;
}

.player__controls > * {
  flex: 1 1;
  margin-bottom: 8px;
}

.progress {
  flex: 10 1;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 3px;
  transition: height 0.3s;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.progress__filled {
  width: 50%;
  background: #ffffff;
  flex: 0 1;
  flex-basis: 100%;
}

input[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  margin: 0 5px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0 0 1px rgba(13, 13, 13, 0);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1.3px;
  border: 0.2px solid rgba(1, 1, 1, 0);
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #ffc600;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0 0 1px rgba(13, 13, 13, 0);
  background: #ffffff;
  border-radius: 1.3px;
  border: 0.2px solid rgba(1, 1, 1, 0);
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #ffc600;
  cursor: pointer;
}
.player__watermark {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.play__button {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.player__top {
  position: absolute;
  width: 100%;
  padding: 0 12px;
  top: 16px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .mobile-hide {
    display: none;
  }

  .video-container {
    margin: 0;
    padding: 0;
    height: 91vh;
    width: 100%;

    object-fit: cover;
  }
  .player {
    border-radius: 0;
    object-fit: cover;
  }

  .player__video {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    object-fit: cover;
  }
}

video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}

